import React from 'react';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import sAction from 'sAction';

export default class CheckBox extends React.PureComponent {
    constructor(props) {
        super(props);

        this.input = React.createRef();
    }

    /**
   *
   * @param {Event} e
   */
    onKeyDown(e) {
        const keyCode = e.keyCode;
        if (keyCode === 13 && this.props.onChange !== undefined) {
            this.props.onChange(!this.props.defaultValue);
        } else if (this.props.onKeyDown !== undefined) {
            this.props.onKeyDown(e);
        }
    }
    componentDidMount() {
        const autoSetValue = this.props.autoSetValue;
        if (autoSetValue === true && this.props.onChange != null) {
            this.props.onChange(this.props.defaultValue);
        }
        this.input.current.nextSibling.addEventListener('click', this.change);
    }
    change = () => {
        if (this.props.onChange != null) {
            this.props.onChange();
        }
    };

    render() {
        const defaultValue = parseInt(this.props.defaultValue);
        const readonly = this.props.readonly;
        let checkClass = false;
        let className = 'acmCheckBox off';
        if (defaultValue === 1) {
            checkClass = true;
            className = 'acmCheckBox';
        }

        if (this.props.className != null) {
            className += ' ' + this.props.className;
        }

        const color = this.props?.color ? (this.props.color.startsWith('#') ? this.props.color : `#${this.props.color}`) : '';
        const backgroundColor = sAction.convertToPastelHSL(color, 1, 'pastel2') || '';

        return (
            <div>
                <Switch
                    disabled={readonly === true ? true : false}
                    className={className}
                    checked={checkClass}
                    inputRef={this.input}
                    id={`switch_${this.props.name}`}
                    onChange={() =>
                        this.props.onChange != null &&
            this.props.onChange(!this.props.defaultValue)
                    }
                    sx={{
                        '& .MuiSwitch-thumb': {
                            backgroundColor: backgroundColor,
                        },
                    }}
                />
                <input type="hidden" id={this.props.name} defaultValue={defaultValue} />
            </div>
        );
    }
}
CheckBox.propTypes = {
    autoFocus: PropTypes.bool,
    autoSetValue: PropTypes.bool,
    className: PropTypes.any,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    readonly: PropTypes.bool,
    color: PropTypes.string,
};
