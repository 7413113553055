import React from 'react';
import PropTypes from 'prop-types';

import PureComponent from '../pure';
import sAction from 'sAction';
import DetailViewContainer from '../DetailViewNew/DetailViewContainer';
import ActivitiesTimeline from './ActivitiesTimeline/ActivitiesTimeline';
import ViewPanelMenu from '../viewPanelMenu/ViewPanelMenu';
import ProductSelector from './Quotes/productSelector';
import MassUpdateContent from './MassUpdate/MassUpdateContent';
import EmailDetailHeader from '../email/EmailDetailHeader';
import ListViewSearch from '../list/ListViewSearch.js';
import Error from '../Errors/ErrorView';
import Subpanels from '../subpanel/Subpanels';
import Loader from '../loader';
import RightPanelImage from './RightPanelImage';
import RightPanelPDF from './RightPanelPDF.js/RightPanelPDF';
import RightPanelVideo from './RightPanelVideo';
import RightPanelWord from './RightPanelWord';
import RightPanelExcel from './RightPanelExcel';
import TooltipWrapper from '../Tooltip/TooltipWrapper';

const EmailDetail = React.lazy(() => import('../email/EmailDetail'));

export default class RightPanel extends PureComponent {
    constructor(props) {
        super(props);
        this.panel = React.createRef();
        this.state = {
            isResizing: false,
        };
    }

    setIsResizing = (prom) => {
        this.setState({...this.state, isResizing: prom});
    };

    close = () => {
        const reloadOnClose = sAction.dataGet('rightPanel/reloadOnClose');
        const data = this.props.data.get('data');
        sAction.closeRightPanel();
        if (data.get('reloadList') != null && reloadOnClose === true) {
            const way = data.get('reloadList');
            sAction.reloadList(0, 1, way);
        }
    };

    /**
     * @param {string} module
     * @param {string} id
     */
    openRecord(module, id) {
        window.open('#detail/'+module+'/'+id, '_blank');
    }

    handleMousedown = (e) => {
        e.preventDefault();
        if (this.state.isResizing) return;
        this.setIsResizing(true);
        document.body.addEventListener('mousemove', this.handleMousemove);
        document.addEventListener('mouseup', this.handleMouseup);

        document.body.addEventListener('touchmove', this.handleMousemove);
        document.addEventListener('touchend', this.handleMouseup);
    };

    handleMousemove = (e) => {
        e.preventDefault();

        let newWidth = window.innerWidth - (e.pageX || e.targetTouches[0].pageX) - 10;
        if (newWidth < 400) {
            newWidth = 400;
        }

        document.querySelector('.rightPanelContainer').style.width = newWidth + 'px';

        if (this.props.data.get('content') === 'productView') {
            sAction.afterLoadListView(this.props.data.data.groupWay);
        }
    };

    handleMouseup = () => {
        this.setIsResizing(false);
        document.body.removeEventListener('mousemove', this.handleMousemove);
        document.removeEventListener('mouseup', this.handleMouseup);

        document.body.removeEventListener('touchmove', this.handleMousemove);
        document.removeEventListener('touchend', this.handleMouseup);
    };

    /**
     * create header for file preview
     * @returns {JSX.Element}
     */
    getFileHeaderContent() {
        const data = this.props.data?.data;
        const downloadFn = data?.get('downloadFn');
        const fileName = data?.get('name');

        return (
            <div className="rightPanelHeaderContent">
                {fileName && <div className="rightPanelHeaderContentTitle">
                    {fileName}
                </div>}
                {downloadFn && <div className="rightPanelHeaderContentActions">
                    <div className="rightPanelHeaderActionsDownload icon-download" onClick={() => downloadFn()} />
                </div>}
            </div>
        );
    }

    render() {
        const data = this.props.data;
        let content = data.content;
        let headerContent = null;

        const customWidth = data.get('customWidth');

        switch (content) {
            case 'load':
                content = <Loader />;
                break;
            case 'detailView':
                content = (
                    <DetailViewContainer
                        key="detail"
                        a={this.props.action}
                        data={data.data.get('detail')}
                        way="rightPanel/data/detail"
                        prefix="rightPanel/data/detail"
                        namespace={this.props.namespace}
                    />
                );
                headerContent = <React.Fragment>
                    <ViewPanelMenu data={data.data.get('detail')} prefix="rightPanel/data/detail" />
                    <TooltipWrapper label={'LBL_OPEN_IN_NEW_WINDOW'}>
                        <div
                            className="rightPanelClose icon-expandIcon"
                            onClick={() => this.openRecord(data.data.getIn(['detail', 'module']), data.data.getIn(['detail', 'id']))}
                        />
                    </TooltipWrapper>
                </React.Fragment>;
                break;
            case 'productView':
                headerContent = (
                    <div className="productSelectorListViewSearchContainer">
                        <ListViewSearch
                            module="ProductTemplates"
                            prefix="rightPanel/data/listView"
                            data={data.data.listView.filter}
                            namespace={this.props.namespace}
                        />
                    </div>
                );

                // console.log("productView", );

                content = (
                    <ProductSelector data={data.data} detailWay="view" />
                );

                // Fixing columns width. Timeout is important due animation
                setTimeout(() => {
                    sAction.afterLoadListView(this.props.data.data.groupWay);
                }, 150);

                break;

            case 'emailRelations':
                content = (
                    <div className="email__relations">
                        <Subpanels data={data.data.get('detail').subpanels} way="rightPanel/data/detail" />
                    </div>
                );
                break;
            case 'emailView':
                content = (
                    <div className="emailRightPanelContainer">
                        <React.Suspense fallback={<Loader />}><EmailDetail data={data.data} hideHeader /></React.Suspense>
                    </div>
                );
                headerContent = (
                    <React.Suspense fallback={<Loader />}><EmailDetailHeader data={data.data} /></React.Suspense>
                );
                break;
            case 'massUpdateView':
                content = (
                    <MassUpdateContent data={data.data} />
                );
                break;
            case 'activitiesTimelineView':
                // eslint-disable-next-line no-case-declarations
                const activitiesTimelinePrefix = 'rightPanel';
                content = (<ActivitiesTimeline prefix={activitiesTimelinePrefix+'/data'} data={data.data}/>);
                headerContent = (
                    data.data.name &&
                    <div className="activityName">
                        <span className={`icon-${data.data.module} activity${data.data.module}`}></span>{data.data.name}
                    </div>
                );
                break;
            case 'error':
                content = (<Error data={data.data}/>);
                break;
            case 'image':
                content = (<RightPanelImage data={data.data} />);
                headerContent = this.getFileHeaderContent();
                break;
            case 'pdf':
                content = (<RightPanelPDF data={data.data} />);
                headerContent = this.getFileHeaderContent();
                break;
            case 'mp4':
                content = (<RightPanelVideo data={data.data} />);
                headerContent = this.getFileHeaderContent();
                break;
            case 'docx':
                content = (<RightPanelWord data={data.data} />);
                headerContent = this.getFileHeaderContent();
                break;
            case 'xlsx':
                content = (<RightPanelExcel data={data.data} />);
                headerContent = this.getFileHeaderContent();
                break;
        }

        // pokud je aktivni prihlasit se jako je potreba posunout rightpanel niz
        const additionalHeight = {};
        if (document.querySelector('.headerAlertPanel')) {
            additionalHeight.marginTop = '64px';
        }
        if (customWidth !== null) {
            additionalHeight.width = customWidth;
        }

        return (
            <div className="rightPanelContainer" style={additionalHeight} ref={this.panel}>
                <div
                    className={'rightPanel_resizer'}
                    onMouseDown={ (e) => {
                        this.handleMousedown(e);
                    }}
                    onTouchStart={ (e) => {
                        this.handleMousedown(e);
                    }}
                >
                    <div className={'rightPanel_resizer-btn' + (this.state.isResizing ? ' rightPanel_resizer--active' : '')}>
                        ||
                    </div>
                </div>
                <div className="rightPanelHeader">
                    <TooltipWrapper label={'LBL_CLOSE_WINDOW'}>
                        <div
                            className="icon-Close rightPanelClose"
                            onClick={() => this.close()}
                        />
                    </TooltipWrapper>
                    {headerContent}
                </div>
                <div className="rightPanelContent">{content}</div>
            </div>
        );
    }
}

RightPanel.propTypes = {
    data: PropTypes.object,
    action: PropTypes.string,
    namespace: PropTypes.string,
};
