import sAction from 'sAction';

/**
 *
 * @param {Object} editor
 * @param {String} isAdmin
 */
export default function panels(editor) {
    const admin = sAction.dataGet('conf/user/is_admin');
    editor.Panels.removeButton('devices-c', 'set-device-tablet');
    editor.Panels.removeButton('devices-c', 'set-device-mobile');
    if (admin === '0' && sAction.getStorage('debug')) {
        editor.Panels.removeButton('options', 'export-template');
        editor.Panels.removeButton('options', 'gjs-open-import-webpage');
        editor.Panels.removeButton('options', 'sw-visibility');
        editor.Panels.removeButton('views', 'open-tm');
    }
}
