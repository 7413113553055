import sAction from 'sAction';

/**
 *
 * @param {Object} editor
 * @param {String} module
 */
export default function variables(editor, module) {
    const rte = editor.RichTextEditor;
    sAction.rest.post('getFieldsForPrintKits', {module}, (res) => {
        let variables = '<select class="variablesSelect">';
        res.data.forEach((item) => {
            variables = variables + `<option value="{#${item.value}#}">${item.name}</option>`;
        });
        variables = variables + '</select>';

        rte.add('variables', {
            icon: variables,
            event: 'change',
            class: 'variables',
            result: (rte, action) => rte.insertHTML(action.btn.firstChild.value),
            update: (rte, action) => {
                action.btn.firstChild.value = '';
            },
        });
    }, false);
}
