import React from 'react';
import MaterialDefault from './MaterialDefault';
import PropTypes from 'prop-types';
import {
    Select as MuiSelect,
    FormControl,
    MenuItem,
    InputLabel,
    ListSubheader,
    TextField,
    InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import sAction from 'sAction';

export default class Select extends MaterialDefault {
    constructor(props) {
        super(props);
        let value = '';
        if (this.props.defaultValue != null) {
            value = this.props.defaultValue;
        }

        let open = false;
        if (this.props.open != null) {
            open = this.props.open;
        }
        const options = this.sortOptions(this.props.options);
        this.state = {
            value: value,
            focus: false,
            openOnFocus: true,
            open: open,
            options: options,
            searchText: '',
        };
        this.options = options;
    }

    componentDidUpdate(prevProps) {
        if (this.props.options !== prevProps.options) {
            const newOptions = this.sortOptions(this.props.options);
            this.setState({options: newOptions});
            this.options = newOptions;
        }
    }

    /**
     *
     * @param {Object} options
     * @returns {Array}
     */
    sortOptions(options) {
        const newOptions = options.sort((a, b) => a.label - b.label);
        return newOptions;
    }

    /**
     *
     * @param {*} value
     */
    searchText(value) {
        this.setState({searchText: value});
        let options = this.options;

        options = options.filter((option) => {
            if (
                option.label.toLowerCase().includes(value.toLowerCase()) || (
                    this.props.allowSearchInValues &&
                    option.value.toLowerCase().includes(value.toLowerCase())
                )
            ) {
                return option;
            }
        });

        if (options[0]?.value !== 'acm_add_new') {
            options = [this.options[0]].concat(options);
        }
        this.setState({options: options});
    }

    /**
     *
     * @param {Object} e
     */
    onChange(e) {
        this.setState({
            value: e.target.value,
            open: false,
        });
        if (this.props.onChange != null) {
            this.props.onChange(e);
        }
    }
    /**
     *
     * @param {Object} e
     */
    onClose(e) {
        this.setState({
            open: false,
            searchText: '',
            options: this.options,
        });
        setTimeout(() => { }, 100);
        if (this.props.onClose != null) {
            this.props.onClose(e);
        }
    }
    /**
     *
     * @param {Object} e
     */
    onOpen(e) {
        this.setState({open: true});
        if (this.props.onOpen != null) {
            this.props.onOpen(e);
        }
    }
    /**
     *
     * @param {Object} e
     */
    onBlur(e) {
        if (this.props.onBlur != null) {
            this.props.onBlur(e);
        }
    }
    /**
     *
     * @param {Object} e
     */
    onKeyDown(e) {
        if (e.keyCode === 13 && this.state.open === false) {
            this.setState({open: true});
        }

        if (this.props.onKeyDown != null) {
            this.props.onKeyDown(e);
        }
    }
    /**
     *
     * @param {Object} e
     */
    onFocus(e) {
        if (this.props.onFocus != null) {
            this.props.onFocus(e);
        }
    }

    /**
     *
     * @returns {*}
     */
    renderOptionLabelByValue() {
        const renderOption = this.state.options.find((option) => {
            if (option.value === (this.state.value || this.props.value)) {
                return option;
            }
        });

        return renderOption?.label;
    }

    render() {
        let labelRender = null;
        if (this.props.label != null) {
            labelRender = (
                <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
            );
        }
        // const options = ;
        const colors = this.props.colors;

        const optionsRender = [];
        this.state.options.forEach((value, key) => {
            let isDisabled = false;
            if (value.disabled) {
                isDisabled = true;
            }

            if (typeof(value.label) === 'string') {
                value.label = sAction.decodeHTMLEntities(value.label);
            }
            if (this.state.options.length > 200) {
                if (value.type !== 'header') {
                    optionsRender.push(
                        <option key={key} value={value.value} disabled={isDisabled}>
                            {value.label} {sAction.getStorage('debug') && ' ['+value.value+']'}
                        </option>,
                    );
                }
            } else {
                if (value.type === 'header') {
                    let style = null;
                    if (colors && colors[value.value]) {
                        style = {backgroundColor: colors[value.value], color: colors[value.value]};
                    }
                    optionsRender.push(
                        <MenuItem key={key} disabled={true} className="acmSelectHeader">
                            {style ? (
                                <span className='colorList listFilter' style={style}> </span>
                            ) : null}
                            {value.label} {sAction.getStorage('debug') && ' ['+value.value+']'}
                        </MenuItem>,
                    );
                } else {
                    let style = null;
                    if (colors && colors[value.value]) {
                        style = {backgroundColor: colors[value.value], color: colors[value.value]};
                    }
                    if (colors && colors[key]) {
                        style = {backgroundColor: colors[key].value};
                    }
                    optionsRender.push(
                        <MenuItem
                            key={key}
                            disabled={isDisabled}
                            value={value.value}
                            className={value.type === 'button' ? 'selectButton' : ''}
                        >
                            {style ? (
                                <span className='colorList listFilter' style={style}> </span>
                            ) : null}
                            {value.label}{sAction.getStorage('debug') && ' ['+value.value+']'}
                        </MenuItem>,
                    );
                }
            }
        });
        let className = 'acmSelectContainer';
        if (this.state.focus) {
            className = 'acmSelectContainer focus';
        }
        if (this.props.error) {
            className += ' error';
        }
        // const attr = this.createAttr("acmSelect");

        let value = this.state.value;
        if (this.props.value != null) {
            value = this.props.value;
        }

        const isOptionsListLong = (this.props.options.length >= 10);

        return (
            <FormControl
                className={
                    this.props.containerClassName != null ?
                        className + ' ' + this.props.containerClassName :
                        className
                }
                disabled={this.props.disabled}
                onKeyDown={(e) => this.onKeyDown(e)}
                autoFocus={this.props.autoFocus}
                style={this.props.containerStyle || null}
                variant="standard"
            >
                {labelRender}

                <MuiSelect
                    native={optionsRender.length > 200}
                    className="acmSelect"
                    labelId="search-select-label"
                    value={value}
                    id={this.props.id}
                    inputProps={{id: this.props.id}}
                    onChange={(e) => this.onChange(e)}
                    onOpen={(e) => this.onOpen(e)}
                    onClose={(e) => this.onClose(e)}
                    onBlur={(e) => this.onBlur(e)}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    onFocus={(e) => this.onFocus(e)}
                    inputRef={this.props.myRef}
                    open={this.state.open}
                    autoFocus={this.props.autoFocus}
                    name={this.props.name}
                    style={this.props.style || null}
                    autoWidth={this.props.autoWidth}
                    renderValue={() => this.renderOptionLabelByValue()}
                    MenuProps={{
                        autoFocus: !isOptionsListLong,
                        ...this.props.menuProps,
                    }}
                >
                    {/* TextField is put into ListSubheader so that it doesn't
                            act as a selectable item in the menu
                            we can click the TextField without triggering any selection.*/}

                    {isOptionsListLong && (
                        <ListSubheader>
                            <TextField
                                size="small"
                                autoFocus={this.props.autoFocus}
                                placeholder={sAction.translate('LBL_TYPE_TO_SEARCH')}
                                fullWidth
                                onBlur={(e) => this.onBlur(e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => this.searchText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key !== 'Escape') {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                    }
                                }}
                            />
                        </ListSubheader>
                    )}
                    {optionsRender}
                </MuiSelect>

            </FormControl>
        );
    }
}

Select.propTypes = {
    myRef: PropTypes.any,
    menuProps: PropTypes.any,
    name: PropTypes.any,
    style: PropTypes.any,
    autoWidth: PropTypes.any,
    value: PropTypes.any,

    autoFocus: PropTypes.any,
    disabled: PropTypes.any,
    containerStyle: PropTypes.any,
    containerClassName: PropTypes.any,
    id: PropTypes.any,
    label: PropTypes.any,

    defaultValue: PropTypes.any,
    open: PropTypes.any,
    options: PropTypes.any,
    error: PropTypes.any,
};
