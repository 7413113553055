import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';
import sAction from 'sAction';
import {Pagination, Select, MenuItem} from '@mui/material';

export default function ListViewPagination(props) {
    const [rowPageCount, setRowPageCount] = useState(props.limit);
    const [currentPage, setCurrentPage] = useState(1);
    const isSubpanel = props.prefix.includes('subpanel');
    const isOnDashboard = props.prefix.includes('tabs');
    const isActivity = props.module === 'Activities' || props.module === 'History';
    let rowTotalCount = props.rowTotalCount;

    if (isActivity && props.rowCount < props.limit) {
        rowTotalCount = currentPage * props.limit;
    } else if (isActivity && props.rowCount === props.limit) {
        rowTotalCount = (currentPage * props.limit) + props.limit;
    }

    useEffect(() => {
        if (!isActivity) {
            sAction.listViewCount(props.prefix, false);
        }
    }, [props.module]);

    useEffect(() => {
        if (rowPageCount !== props.limit) {
            sAction.dataSet(`${props.prefix}/limit`, rowPageCount);
            pagination(1);
        }
    }, [rowPageCount]);

    /**
     * setup pagination based on list type
     * @param {number} page listview type
     */
    const pagination = (page) => {
        const data = {
            offset: props.offset,
            limit: rowPageCount,
            rowCount: props.rowCount,
            rowTotalCount: rowTotalCount,
            page: page,
            prefix: props.prefix,
        };
        sAction.listPagination(data);
    };

    const pageNumbersEnum = sAction.getEnumForSelect('list_pagination');

    return (
        <div className='paginationWrapper'>
            {!isSubpanel && !isOnDashboard &&
            <>
                <TooltipWrapper label='LBL_PAGE_RECORDS_NUMBER' placement='left'>
                    <Select
                        variant='standard'
                        size='small'
                        className='paginationRowsSelect'
                        onChange={(e) => setRowPageCount(parseInt(e.target.value))}
                        value={rowPageCount}
                    >
                        {Object.keys(pageNumbersEnum).map((key) => {
                            const item = pageNumbersEnum[key];
                            return (
                                <MenuItem key={key} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            );
                        })}
                    </ Select>
                </TooltipWrapper>
            </>}
            <Pagination
                count={Math.ceil(rowTotalCount / rowPageCount)}
                showFirstButton
                showLastButton
                page={props.page}
                rowsPerPage={rowPageCount}
                // shape='rounded'
                className='newPagination'
                onChange={(event, page) => {
                    setCurrentPage(page);
                    pagination(page);
                }}
                sx={isSubpanel && {
                    '& .MuiPaginationItem-page': {display: 'none'},
                    '& .MuiPaginationItem-ellipsis': {display: 'none'},
                }}
            />
            {!isActivity && (
                <TooltipWrapper label='LBL_RECORDS_COUNT'>
                    <span className='newPagination__count'>{`${rowTotalCount === null ? 0 : rowTotalCount}`}</span>
                </TooltipWrapper>
            )}
        </div>
    );
}

ListViewPagination.propTypes = {
    isMaxSize: PropTypes.bool,
    limit: PropTypes.number.isRequired,
    module: PropTypes.string,
    offset: PropTypes.number,
    page: PropTypes.number.isRequired,
    prefix: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    rowTotalCount: PropTypes.number,
    type: PropTypes.string,
    namespace: PropTypes.string,
    query: PropTypes.string,
};
