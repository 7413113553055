/**
 *
 * @param e
 */
export default function rowResizeMove(e) {
    if (this.activeResizeRow) {
        const actX = e.clientX || e.targetTouches[0].pageX;
        let vysledekX = actX - this.resizeStart;

        let width = this.resizeWidthStart+vysledekX;

        this.resizeRows.forEach((element) => {
            // Pro filtr slozeny ze selektu a inputu width na 150
            if (
                element.firstChild !== null &&
                element.firstChild.childNodes.length > 1 &&
                element.firstChild.childNodes[0].classList.contains('acmSelectContainer') &&
                element.firstChild.childNodes[1].classList.contains('acmInputContainer')
            ) {
                width = Math.max(250, width);
            } else {
                width = Math.max(100, width);
            }
        });

        this.resizeRows.forEach((element) => {
            element.style.minWidth = width + 'px';
            element.style.maxWidth = width + 'px';
        });
    }
}
