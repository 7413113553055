/**
 * @param {object} params
 * @param {callback} callback
 */
export default function openDynamicEnum(params, callback) {
    const currentLanguage = this.dataGet('conf/language');

    this.rest.post('dynamicEnumList', params, (returnData) => {
        returnData['lines'] = returnData['lines'].sort((a, b) =>
            this.translate(String(a.name), params.enum).localeCompare(this.translate(String(b.name), params.enum)), currentLanguage);
        callback(returnData);
    }, false);
}
