import sAction from 'sAction';

/**
 * @param {Object} editor
 * @param {String} module
 * @returns {Array}
 */
export default function lines(editor, module) {
    /**
     * @param {Bool} headerValues
     * @returns {Array}
     */
    const getLinesColumns = (headerValues = false) => {
        const columns = [];
        sAction.rest.post('getLinesFields', {module}, (res) => {
            if (!headerValues) {
                res.forEach((item, i) => {
                    columns.push(
                        {
                            'tagName': 'td',
                            'classes': ['column', `column-${i}`],
                            'data-gjs-droppable': '.column',
                            'content': `{*${item.value}*}`,
                        },
                    );
                });
            } else {
                res.forEach((item, i) => {
                    columns.push(
                        {
                            'tagName': 'td',
                            'classes': ['column', `column-${i}`],
                            'data-gjs-droppable': '.column',
                            'components': [
                                {
                                    tagName: 'span',
                                    type: 'text',
                                    content: `${item.label}`,
                                },
                            ],
                        },
                    );
                });
            }
        }, false);
        return columns;
    };

    /**
     *
     * @param {Number} numberOfEmptyColumns
     * @returns {Array}
     */
    const renderFirstLineSum = (numberOfEmptyColumns) => {
        const columns = [];
        columns.push(
            {
                'tagName': 'td',
                'data-gjs-droppable': '.column',
                'content': `Celková částka`,
                'attributes': {
                    'contenteditable': true,
                },
            },
        );
        for (let i = 0; i < numberOfEmptyColumns; i++) {
            columns.push(
                {
                    'tagName': 'td',
                    'data-gjs-droppable': '.column',
                },
            );
        }
        columns.push(
            {
                'tagName': 'td',
                'data-gjs-droppable': '.column',
                'content': `{#cena_bez_dph_celkem#}`,
            },
        );
        columns.push(
            {
                'tagName': 'td',
                'data-gjs-droppable': '.column',
                'content': `{#cena_dph_celkem#}`,
            },
        );
        columns.push(
            {
                'tagName': 'td',
                'data-gjs-droppable': '.column',
                'content': `{#cena_s_dph_celkem#}`,
            },
        );

        return columns;
    };

    /**
     *
     * @param {Number} numberOfEmptyColumns
     * @returns {Array}
     */
    const renderSecondLineSum = (numberOfEmptyColumns) => {
        const columns = [];
        if (module === 'Quotes') {
            for (let i = 0; i < 5; i++) {
                columns.push(
                    {
                        'tagName': 'td',
                        'data-gjs-droppable': '.column',
                    },
                );
            }
            columns.push(
                {
                    'tagName': 'td',
                    'data-gjs-droppable': '.column',
                    'content': `<b>Celkem</b>`,
                    'attributes': {
                        'contenteditable': true,
                    },
                },
            );
            columns.push(
                {
                    'tagName': 'td',
                    'data-gjs-droppable': '.column',
                },
            );
            columns.push(
                {
                    'tagName': 'td',
                    'data-gjs-droppable': '.column',
                },
            );
            columns.push(
                {
                    'tagName': 'td',
                    'data-gjs-droppable': '.column',
                    'content': `<b>{#total#}</b>`,
                    'attributes': {
                        'contenteditable': true,
                    },
                },
            );
        } else {
            columns.push(
                {
                    'tagName': 'td',
                    'data-gjs-droppable': '.column',
                    'content': `<b>Celkem k zaplacení</b>`,
                    'attributes': {
                        'contenteditable': true,
                    },
                },
            );
            for (let i = 0; i < numberOfEmptyColumns; i++) {
                columns.push(
                    {
                        'tagName': 'td',
                        'data-gjs-droppable': '.column',
                    },
                );
            }
            columns.push(
                {
                    'tagName': 'td',
                    'data-gjs-droppable': '.column',
                    'attributes': {
                        'contenteditable': true,
                    },
                    'content': '<b>Měna: {#currency_id#}</b>',
                },
            );
            columns.push(
                {
                    'tagName': 'td',
                    'data-gjs-droppable': '.column',
                },
            );
            columns.push(
                {
                    'tagName': 'td',
                    'data-gjs-droppable': '.column',
                    'content': `<b>{#cena_s_dph_celkem#}</b>`,
                },
            );
        }

        return columns;
    };

    editor.Blocks.add('detail_lines', {
        label: 'Lines',
        media: '<span class="iconfas-lines printKitsBockIcon"></span>',
        activate: true,
        id: 'lines-table',
        content: {
            type: 'table',
            style: {
                'border-collapse': 'collapse',
            },
            components: [
                {
                    tagName: 'lines',
                    attributes: {
                        contenteditable: true,
                        id: 'lines',
                    },
                    components: [
                        {
                            tagName: 'tr',
                            components: getLinesColumns(true),
                        },
                        {
                            tagName: 'tr',
                            components: getLinesColumns(),
                        },
                        {
                            tagName: 'tfoot',
                            components: [
                                {
                                    tagName: 'tr',
                                    components: [
                                        {
                                            'tagName': 'td',
                                            'classes': [`column-1`],
                                            'data-gjs-droppable': '.column',
                                            'style': {
                                                'height': '15px',
                                            },
                                            'attributes': {
                                                'colspan': '8',
                                            },
                                        },
                                    ],
                                },
                                {
                                    tagName: 'tr',
                                    components:
                                                module === 'Quotes' ? '' :
                                                    module === 'acm_invoices' ? renderFirstLineSum(4) :
                                                        renderFirstLineSum(2),
                                },
                                {
                                    tagName: 'tr',
                                    components:
                                                module === 'Quotes' ? renderSecondLineSum(7) :
                                                    module === 'acm_invoices' ? renderSecondLineSum(4) :
                                                        renderSecondLineSum(2),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    });
}
