import sAction from 'sAction';

/**
 * @return {Object}
 */
export default function renderMessages() {
    const module = 'acm_printKits';

    return {
        assetManager: {
            addButton: sAction.translate('LBL_ADD_IMAGE', module),
            inputPlh: 'http://path/to/the/image.jpg',
            modalTitle: sAction.translate('LBL_SELECT_IMAGE', module),
            uploadTitle: sAction.translate('LBL_DROP_FILES_OR_UPLOAD', module),
        },
        blockManager: {
            labels: {
                // 'block-id': 'Block Label',
            },
            categories: {
                // 'category-id': 'Category Label',
            },
        },
        domComponents: {
            names: {
                '': sAction.translate('LBL_BOX', module),
                'wrapper': sAction.translate('LBL_WRAPPER', module),
                'text': sAction.translate('LBL_TEXT', module),
                'comment': sAction.translate('LBL_COMMENT', module),
                'image': sAction.translate('LBL_IMAGE', module),
                'video': sAction.translate('LBL_VIDEO', module),
                'label': sAction.translate('LBL_LABEL', module),
                'link': sAction.translate('LBL_LINK', module),
                'map': sAction.translate('LBL_MAP', module),
                'tfoot': sAction.translate('LBL_TFOOT', module),
                'tbody': sAction.translate('LBL_TBODY', module),
                'thead': sAction.translate('LBL_THEAD', module),
                'table': sAction.translate('LBL_TABLE', module),
                'row': sAction.translate('LBL_ROW', module),
                'cell': sAction.translate('LBL_CELL', module),
            },
        },
        deviceManager: {
            device: sAction.translate('LBL_DEVICE', module),
            devices: {
                desktop: sAction.translate('LBL_DESKTOP', module),
                tablet: sAction.translate('LBL_TABLET', module),
                mobileLandscape: sAction.translate('LBL_MOBILE_LANDSCAPE', module),
                mobilePortrait: sAction.translate('LBL_MOBILE_PORTRAIT', module),
            },
        },
        panels: {
            buttons: {
                titles: {
                    'preview': sAction.translate('LBL_PREVIEW', module),
                    'fullscreen': sAction.translate('LBL_FULLSCREEN', module),
                    'sw-visibility': sAction.translate('LBL_VIEW_COMPONENTS', module),
                    'export-template': sAction.translate('VIEW_CODE', module),
                    'open-sm': sAction.translate('LBL_OPEN_STYLE_MANAGER', module),
                    'open-tm': sAction.translate('LBL_SETTINGS', module),
                    'open-layers': sAction.translate('LBL_OPEN_LAYER_MANAGER', module),
                    'open-blocks': sAction.translate('LBL_OPEN_BLOCKS', module),
                },
            },
        },
        selectorManager: {
            label: sAction.translate('LBL_CLASSES', module),
            selected: sAction.translate('LBL_SELECTED', module),
            emptyState: sAction.translate('LBL_STATE_SELECTOR', module),
            states: {
                'hover': 'Hover',
                'active': 'Click',
                'nth-of-type(2n)': 'Even/Odd',
            },
        },
        styleManager: {
            empty: sAction.translate('LBL_SELECT_ELEMENT_STYLE', module),
            layer: sAction.translate('LBL_LAYER', module),
            fileButton: sAction.translate('LBL_IMAGES', module),
            sectors: {
                general: sAction.translate('LBL_GENERAL', module),
                layout: sAction.translate('LBL_LAYOUT', module),
                typography: sAction.translate('LBL_TYPOGRAPHY', module),
                decorations: sAction.translate('LBL_DECORATIONS', module),
                extra: sAction.translate('LBL_EXTRA', module),
                flex: sAction.translate('LBL_FLEX', module),
                dimension: sAction.translate('LBL_DIMENSION', module),
            },
            // Default names for sub properties in Composite and Stack types.
            // Other labels are generated directly from their property names (eg. 'font-size' will be 'Font size').
            properties: {
                'text-shadow-h': sAction.translate('LBL_X', module),
                'text-shadow-v': sAction.translate('LBL_Y', module),
                'text-shadow-blur': sAction.translate('LBL_BLUR', module),
                'text-shadow-color': sAction.translate('LBL_COLOR', module),
                'box-shadow-h': sAction.translate('LBL_X', module),
                'box-shadow-v': sAction.translate('LBL_Y', module),
                'box-shadow-blur': sAction.translate('LBL_BLUR', module),
                'box-shadow-spread': sAction.translate('LBL_SPREAD', module),
                'box-shadow-color': sAction.translate('LBL_COLOR', module),
                'box-shadow-type': sAction.translate('LBL_TYPE', module),
                'margin-top-sub': sAction.translate('LBL_TOP', module),
                'margin-right-sub': sAction.translate('LBL_RIGHT', module),
                'margin-bottom-sub': sAction.translate('LBL_BOTTOM', module),
                'margin-left-sub': sAction.translate('LBL_LEFT', module),
                'padding-top-sub': sAction.translate('LBL_TOP', module),
                'padding-right-sub': sAction.translate('LBL_RIGHT', module),
                'padding-bottom-sub': sAction.translate('LBL_BOTTOM', module),
                'padding-left-sub': sAction.translate('LBL_LEFT', module),
                'border-width-sub': sAction.translate('LBL_WIDTH', module),
                'border-style-sub': sAction.translate('LBL_STYLE', module),
                'border-color-sub': sAction.translate('LBL_COLOR', module),
                'border-top-left-radius-sub': sAction.translate('LBL_COLOR', module),
                'border-top-right-radius-sub': sAction.translate('LBL_TOP_RIGHT', module),
                'border-bottom-right-radius-sub': sAction.translate('LBL_BOTTOM_RIGHT', module),
                'border-bottom-left-radius-sub': sAction.translate('LBL_BOTTOM_LEFT', module),
                'transform-rotate-x': sAction.translate('LBL_ROTATE_X', module),
                'transform-rotate-y': sAction.translate('LBL_ROTATE_Y', module),
                'transform-rotate-z': sAction.translate('LBL_ROTATE_Z', module),
                'transform-scale-x': sAction.translate('LBL_SCALE_X', module),
                'transform-scale-y': sAction.translate('LBL_SCALE_Y', module),
                'transform-scale-z': sAction.translate('LBL_SCALE_Z', module),
                'transition-property-sub': sAction.translate('LBL_PROPERTY', module),
                'transition-duration-sub': sAction.translate('LBL_DURATION', module),
                'transition-timing-function-sub': sAction.translate('LBL_TIMING', module),
                'background-image-sub': sAction.translate('LBL_IMAGE', module),
                'background-repeat-sub': sAction.translate('LBL_REPEAT', module),
                'background-position-sub': sAction.translate('LBL_POSITION', module),
                'background-attachment-sub': sAction.translate('LBL_ATTACHMENT', module),
                'background-size-sub': sAction.translate('LBL_SIZE', module),
            },
            // Translate options in style properties
            // options: {
            //   float: { // Id of the property
            //     ...
            //     left: 'Left', // {option id}: {Option label}
            //   }
            // }
        },
        traitManager: {
            empty: 'Select an element before using Trait Manager',
            label: 'Component settings',
            traits: {
                // The core library generates the name by their `name` property
                labels: {
                // id: 'Id',
                // alt: 'Alt',
                // title: 'Title',
                // href: 'Href',
                },
                // In a simple trait, like text input, these are used on input attributes
                attributes: {
                    href: {placeholder: 'eg. https://google.com'},
                },
                // In a trait like select, these are used to translate option names
                options: {
                    target: {
                        false: 'This window',
                        _blank: 'New window',
                    },
                },
            },
        },
        storageManager: {
            recover: 'Do you want to recover unsaved changes?',
        },
    };
}
