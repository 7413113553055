import React, {useEffect, useState} from 'react';
import sAction from 'sAction';
import Button from '../../formElements/Button';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import gsWebpage from 'grapesjs-preset-webpage';
import gjsblockbasic from 'grapesjs-blocks-basic';
import renderMessages from './grapeJs/messages';

import exportHtml from './actions/exportHtml';

import panels from './grapeJs/panels';
import variables from './grapeJs/variables';
import blocks from './grapeJs/blocks/blocks';

export default function PrintKits({fields, prefix}) {
    const [editor, setEditor] = useState(null);

    const detailFields = fields.toJS();

    const module = detailFields.module.value;

    useEffect(() => {
        const editor = grapesjs.init({
            container: '#gjs',
            plugins: [gsWebpage, gjsblockbasic],
            storageManager: {type: null},
            i18n: {
                locale: 'en',
                messages: {
                    en: renderMessages(),
                },
            },
        });

        blocks(editor, module);
        panels(editor);
        variables(editor, module);
        setEditor(editor);

        const params = {
            id: detailFields.id.value,
            module: module,
        };

        if (params.module) {
            sAction.rest.post('loadPrintKit', params, (res) => {
                if (res.status) {
                    editor.loadProjectData(res.data);
                }
            }, false);
        }

        return () => {
            editor.destroy();
        };
    }, [module]);

    /**
     *
     */
    const loadPrintKit = () => {
        sAction.popup('selectPrintKit', {
            params: {module, returnTemplate: true},
            callback: (data) => {
                editor.loadProjectData(data);
                sAction.popupHide();
            },
            header: sAction.translate('LBL_LOAD_PRINT_KIT', 'acm_printKits'),
        });
    };

    /**
     * @return {function}
     */
    const savePrintKit = () => {
        const recordId = sAction.dataGet(`${prefix}/id`);
        if (!recordId) {
            return sAction.error(sAction.translate('LBL_PLEASE_SAVE_RECORD_BEFORE_SAVE_PRINT_KIT', 'acm_printKits'));
        }
        const params = {
            module: module,
            id: detailFields.id.value,
            html: exportHtml(editor),
            json: editor.getProjectData(),
        };

        sAction.load();
        sAction.rest.post('savePrintKit', params, (res) => {
            sAction.unLoad();
            if (res.status) {
                sAction.toast({
                    name: sAction.translate(res.message),
                });
            } else {
                sAction.error(sAction.translate(res.message));
            }
        });
    };

    /**
     *
     */
    const resetPrintKit = () => {
        sAction.rest.post('loadTemplateToPrintKit', {id: detailFields.id.value, module: module}, (res) => {
            if (res.status) {
                editor.loadProjectData(res.data);
            }
        });
    };

    return (
        <div className='reportViewContainer printKits'>
            <div className='viewActionPanel'>
                <div className='viewActionPanelButtons'>
                    <Button className='hoverGreen' onClick={() => savePrintKit()}>
                        <div className={'actionPanelButtonIcon icon-saveIcon'}/>
                        {sAction.translate('LBL_SAVE', 'Administration')}
                    </Button>
                    <Button className='hoverBlue' onClick={() => loadPrintKit()}>
                        <div className={'actionPanelButtonIcon icon-eb38'}/>
                        {sAction.translate('LBL_LOAD_PRINT_KIT', 'acm_printKits')}
                    </Button>
                    <Button className='hoverBlue' onClick={() => resetPrintKit()}>
                        <div className={'actionPanelButtonIcon icon-f1ff'}/>
                        {sAction.translate('LBL_RESET_PRINT_KIT', 'acm_printKits')}
                    </Button>
                </div>
            </div>
            <div className='printKitsBox'>
                <div className='detailCard printKitsEditorBox'>
                    <div className='printKitsEditor' id="gjs"></div>
                </div>
            </div>
        </div>
    );
}
