import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ListViewSearch from './ListViewSearch';
import ListViewLooks from './ListViewLooks';
import ListViewToggleDefaultFilter from './ListViewToggleDefaultFilter';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';
import sAction from 'sAction';

export default function ListViewMenu({data, prefix, namespace}) {
    const instanceConfig = useMemo(() => {
        if (!data?.modul || data?.modul !== 'Schedulers') {
            return false;
        }

        const configData = sAction.dataGet('conf/configData');
        if (!configData) {
            return false;
        }

        return configData;
    }, [data?.modul]);

    const clearSearchContext = () => {
        const module = sAction.dataGet(prefix + '/modul');
        const view = prefix.split('/').slice(-1)[0];
        const searchContext = sAction.getStorage('searchContext');

        if (searchContext && searchContext[`${module}_${view}`]) {
            searchContext[`${module}_${view}`] = {
                searchData: [],
            };
        }
        sAction.setStorage('searchContext', searchContext);
    }

    return (
        <div className="listViewActions">
            <div className="listViewActionsRow">
                {data.type !== 'reportWindow' && (
                    <div className={"filterContainer"}>
                        <TooltipWrapper label={'LBL_DO_FILTER'}>
                            <div
                                onClick={() => {
                                    clearSearchContext();
                                    sAction.activateListViewFiltering(prefix);
                                }}
                                className={"icon-filter listViewFilterIcon"}
                            >
                                {data.actFiltering && <i className='filteringUsedMark'></i>}
                            </div>
                        </TooltipWrapper>
                    </div>
                )}
                <ListViewLooks
                    data={data.savedSearch}
                    hasAssignedUser={data.hasAssignedUser}
                    namespace={namespace}
                    module={data.modul}
                    prefix={prefix}
                    actSavedSearch={data.actSavedSearch}
                />
            </div>


            {instanceConfig && instanceConfig !== false && (
                <div className="instanceConfigPreview">
                    {instanceConfig.get('enableCron') === true ? (
                        <TooltipWrapper label={'LBL_CRON_ENABLED_TOOLTIP'} placement={'bottom'}>
                            <div className="cronStatusBadge enabled">
                                <span className="icon iconfas-cron" />
                                <span className="text">{sAction.translate('LBL_CRON_ENABLED')}</span>
                            </div>
                        </TooltipWrapper>
                    ) : (
                        <TooltipWrapper label={'LBL_CRON_DISABLED_TOOLTIP'} placement={'bottom'}>
                            <div className="cronStatusBadge disabled">
                                <span className="icon iconfas-cron" />
                                <span className="text">{sAction.translate('LBL_CRON_DISABLED')}</span>
                            </div>
                        </TooltipWrapper>
                    )}
                </div>
            )}

            {data.defaultFilter.size - !!data.defaultFilter.get('tree') > 0 && (data.actSavedSearch === ' ' || !data.actSavedSearch)?
                <div className="rightSideActions">
                    <ListViewToggleDefaultFilter data={data} defaultFilter={data.defaultFilter} prefix={prefix}/>
                    <ListViewSearch data={data.filter} module={data.modul} prefix={prefix} namespace={namespace}/>
                </div> :
                <ListViewSearch data={data.filter} module={data.modul} prefix={prefix} namespace={namespace}/>
            }
        </div>
    );
}

ListViewMenu.propTypes = {
    data: PropTypes.shape({
        modul: PropTypes.string.isRequired,
        savedSearch: ImmutablePropTypes.list,
        actSavedSearch: PropTypes.string,
        filter: ImmutablePropTypes.list,
        defaultFilter: ImmutablePropTypes.map,
        hasAssignedUser: PropTypes.bool.isRequired,
    }).isRequired,
    prefix: PropTypes.string.isRequired,
    namespace: PropTypes.string.isRequired,
};
