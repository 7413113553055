import React from 'react';
import PropTypes from 'prop-types';

import PureComponent from '../pure';

import HomeTabs from './HomeTabs';

import DragAndDrop from '../dragAndDrop/DragAndDropCoripo';

import sAction from 'sAction';

import HomeTabAdd from './HomeTabAdd';

import ErrorBoundary from './ErrorBoundary';

/**
 * CHILDREN
 */
import HomeColumns from './HomeColumns';

/**
 * @parent  src\components\app.js
 * @props     data={store.appReducer.home}
 *            way="home"
 *
 * zobrazuje zalozky na HP, kde jsou dale boxy na budiky
 */
class Home extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isSingleColumn: false,
        };

        const tabletWidth = 768;

        this.homeRef = React.createRef();

        this.resizeObserver = new ResizeObserver(() => {
            if (this.homeRef.current.clientWidth < tabletWidth) {
                this.setState({isSingleColumn: true});
            } else if (this.homeRef.current.clientWidth > tabletWidth) {
                this.setState({isSingleColumn: false});
            }
        });
    }

    componentDidUpdate() {
        if (!this.homeRef.current) {
            return;
        };

        this.resizeObserver.observe(this.homeRef.current);
    }

    componentWillUnmount() {
        this.resizeObserver.disconnect();
    }

    // zmena aktivniho tabu
    handleChange = (event, value) => {
        const way = this.props.way;
        sAction.dataSet(way+'/activeId', value);
        sAction.setStorage('homeActiveTab', value);
    };

    render() {
        const data = this.props.data;

        if (!data) return '';

        let activeId = data.get('activeId');
        const way = this.props.way;
        const renderDataTabMenu = data.tabs.map((tab) => tab.name);
        const tabSize = data.tabs.size;

        if (!Number.isInteger(activeId) || activeId >= tabSize) {
            activeId = 0;
        }

        return (
            <ErrorBoundary>
                {/* Tab menu */}
                <div
                    className={
                        "pageContainer HomeViewContainer" +
                        (this.state.isSingleColumn && sAction.isDesktop() ? ' homeViewTablet' : '')
                    }
                    ref={this.homeRef}
                >

                    {tabSize > 0 && (
                        <div className="viewActionPanel homePanel">
                            <HomeTabs
                                value={activeId}
                                onChange={this.handleChange}
                                tabs={renderDataTabMenu}
                                variant="scrollable"
                                scrollButtons="auto"
                                way={way + '/tabs'}
                            />
                        </div>
                    )}

                    {/* Obsah tabu */}

                    <div className="home">
                        {tabSize > 0 ? (
                            <DragAndDrop
                                containersClass=".homeDragAndDropContainer"
                                elementsClass=".homeDragAndDropItem"
                                handleElementClass=".homeDragAndDropHolder"
                                afterEnd={() => sAction.homeLayoutSave()}
                            >
                                <HomeColumns data={data.tabs.get(activeId)} way={way+'/tabs/'+activeId+'/cols'} />
                            </DragAndDrop>
                        ) : (
                            <HomeTabAdd />
                        )}
                    </div>
                </div>
            </ErrorBoundary>
        );
    }
}

Home.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.object]),
};

export default Home;
