/**
 *
 * @param {object} data
 * @param {object} paramData
 * @param {func|callback} callback
 */
export default function routeDetailView(data = null, paramData = {}, callback = null) {
    let id = this.getRecordId();
    let module = this.getModuleName();
    let prefix = 'view';
    if (data != null) {
        if (data['id'] !== undefined) {
            id = data['id'];
        }
        if (data['module'] !== undefined) {
            module = data['module'];
        }
        if (data['prefix'] !== undefined) {
            prefix = data['prefix'];
        }
    }

    const content = {
        id,
        module,
    };
    if (data !== null && data !== undefined) {
        content['type'] = data.type;

        if (data.type === 'rightPanel') {
            paramData.quickcreate = true;
            paramData.rightPanel = true;
        }
    }

    const lsData = this.getStorage('Accounts-subpanel-branch');
    if (lsData) {
        paramData.lsData = lsData;
    }

    delete this.detailTempData[prefix];
    this.rest.fetchData(
        `detail/${module}/${id}`,
        'POST',
        paramData,
        false).then((responseData) => {
        data = responseData.data;
        this.HashManager.preprocessDefinitions(data).then((data) => {
            if (module !== 'EmailTemplates') {
                this.detailContent(data, content);
            } else {
                this.emailTemplatesContent(data, content);
            }
            const akce = {
                type: 'PAGELOADDETAIL',
                content: content,
                prefix: prefix,
                function: self,
            };
            this.dsAddCustom(akce);
            if (data['customData'] && !data['customData']['productData']) {
                // if you delete  "&& !data['customData']['productData']" from, it will drop on quotes
                this.dsAdd('set', prefix + '/customData', data['customData']);
            }
            if (data['customData'] && data['customData']['productData'] && module === 'Opportunities') {
                // old opportunities lines
                this.dsAdd('set', `${prefix}/customData`, data['customData']);
            }
            if (data['predefinedFields']) {
                this.dsAdd(
                    'set',
                    prefix + '/changes/fields',
                    data['predefinedFields'],
                );
            }
            if (data['customDataTop']) {
                this.dsAdd('set', prefix + '/customDataTop', data['customDataTop']);
            }

            this.dsAdd('set', prefix + '/tags', data.tags);

            // detect and prevent crash from fast clicking
            const view = this.getViewName();
            const viewFromStore = this.dataGet('conf/view');
            if (viewFromStore !== view) {
                console.warn('[Error] routeDetailView - View name mismatch store:', viewFromStore, 'url:', view);
            }
            this.dsAdd('set', 'conf/view', view);

            this.dsProcess();

            if (data['customData'] && data['customData']['boundModules']) {
                let index = 0;

                this.dsAdd('set', prefix + '/customData/boundModules', null);
                data['customData']['boundModules'].toJS().forEachObject((detail, module) => {
                    const contentA = {
                        id,
                        module,
                    };
                    this.detailContent(detail, contentA);
                    const akce = {
                        type: 'PAGELOADDETAIL',
                        content: contentA,
                        prefix: prefix + '/boundModules/' + index,
                        function: self,
                    };
                    this.dsAddCustom(akce);
                    this.dsAdd('set', prefix + '/boundModules/' + index + '/customData', {});
                    this.dsAdd('set', prefix + '/boundModules/' + index + '/customData', detail.customData);

                    index++;
                });
                this.dsProcess();
            }

            this.detailUrlParamsToFields(prefix);
            // vraceni stromku kategorii v subpanelech na první misto
            if (prefix === 'view') {
                const selectItem = document.querySelectorAll(
                    '.subpanel .acmTreeSelect',
                );
                selectItem.forEach((item) => {
                    item.style.top = '0px';
                });
            }
            // END
            this.unLoad();
            this.afterLoadListView(undefined, undefined, 'subpanel');
            if (callback) {
                callback();
            }
        });
    },
    paramData['cancelPreviousRequest'] ?? true,
    ).catch((responseData) => {
        this.unLoad();
        const header = this.translate(responseData?.text) ?? this.translate('LBL_ERROR');
        const body = this.translate(responseData?.data?.errorDescription) ?? '';
        if (content['type'] === 'rightPanel') {
            this.error(body, header, () => {
                this.popupHide();
                this.closeRightPanel();
            });

            return;
        }

        this.dsClear();
        this.dsAdd('set', `conf/view`, 'error');
        this.dsAdd('set', `conf/load`, false);
        const errorData = {type: 'detailViewErrors', subject: header, body: body};
        this.dsAdd('set', `${prefix}`, errorData);
        this.dsProcess();
    });
}
