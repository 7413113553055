/**
 *
 * @param {object} data
 */
export default function listPagination(data) {
    this.dsClear();

    const limit = data.limit;
    const rowTotalCount = data.rowTotalCount;
    const page = data.page;
    const prefix = data.prefix;
    let list = null;

    if (this.getViewName() === 'import') {
        const action = this.getStorage('prepareListStructure');
        list = this.prepareListStructure(action);
    }

    const sendOffset = (page * limit) - limit;
    this.reloadList(sendOffset, page, prefix, null, rowTotalCount, list);
    const listElem = document.getElementById('newListViewContentInner');
    if (listElem) {
        listElem.scrollTop = 0;
    }
}
