import React from 'react';
import PureComponent from '../pure';
import ImmutablePropTypes from 'react-immutable-proptypes';
import sAction from 'sAction';
import EnumField from './filterFields/enumField';
import BoolField from './filterFields/boolField';
import TextField from './filterFields/textField';
import DateField from './filterFields/DateField';
import ParentField from './filterFields/ParentField';
import RelateField from './filterFields/RelateField';
import MultirelateField from './filterFields/MultirelateField';
import propTypes from 'prop-types';
import classnames from 'classnames';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class NewListViewHeaderField extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            filterOpen: false,
            doOpen: true,
            resizing: false,
        };
        this.iconContainer = React.createRef();
        this.headerInput = React.createRef();
        this.interval = null;
        this.index = 0;
    }

    componentDidMount() {
        window.addEventListener(
            'mouseup',
            (e) => {
                if (sAction.activeResizeRow) {
                    this.setState({resizing: true})
                } else {
                    this.setState({resizing: false})
                }
            },
            {passive: false});
    }

    /**
     * headerInputSearch - search in header input
     * @param {String} value
     * @param {Object} customParam
     */
    headerInputSearch(value, customParam = {}) {
        const data = this.props.data;
        const prefix = this.props.prefix;
        const fieldModule = data.def.get('fieldModule');
        let fieldRel = data.fieldRel;
        const relationField = data.def.get('relationField');
        if (this.interval) {
            clearTimeout(this.interval);
        }
        if (sAction.dataGet(`${prefix}/selected`).size !== 0 && sAction.dataGet('conf/popup/filePath') !== 'listview/filterChange.js') {
            this.interval = setTimeout(() => {
                sAction.dynPopup('listview/filterChange.js', {prefix: prefix});
            }, 1000);
        }

        let operand = 'cont';
        let operator = 'and';
        let field = data.def.get('name');

        const type = data.def.get('type');

        if (type === 'enum') {
            operand = 'eq';
        }
        if (type === 'relate' || field === 'acm_contacts_text') {
            operator = 'or';
        }
        if (type === 'relate') {
            operand = 'eq';

            field = data.def.get('id_name');

            if (!fieldRel && data.def.get('link')) {
                field = 'id';
                fieldRel = [data.def.get('link')];
            }
        }
        if (type === 'multienum' && field !== 'acm_contacts_text') {
            value = '^'+value+'^';
        }

        customParam['fieldType'] = type;
        if (data.def.get('inlineSearchMultiValue')) {
            customParam['multiValue'] = true;
        }

        if (value === '') {
            const filter = {
                field: field,
                fieldRel: fieldRel,
                type: null,
                value: null,
            };
            const filters = new Array(filter);
            const data = {
                field: field,
                filters: filters,
                operator: 'and',
                prefix: prefix,
                fieldRel: fieldRel,
            };
            sAction.removeFilter(data);
            return;
        }

        const filters = [];

        /**
         *
         * @param {string} value
         * @returns {{field: *, fieldModule: *, type: string, value, fieldRel: *}}
         */
        const getFilterData = (value) => {
            return {
                field: field,
                fieldModule: fieldModule,
                fieldRel: fieldRel,
                value: value,
                type: operand,
                relationField: relationField,
                ...customParam,
            };
        };

        if (Array.isArray(value)) {
            value.forEach((item) => {
                filters.push(getFilterData(item));
            });
        } else {
            filters.push(getFilterData(value));
        }

        const paramData = {
            filters: filters,
            prefix: prefix,
            operator: operator,
            field: field,
            fieldModule: fieldModule,
            fieldRel: fieldRel,
            reload: true,
        };

        sAction.addFilter(paramData);
    }

    /**
     * openFilter - open filter
     * @return {void}
     */
    openFilter() {
        const data = this.props.data;
        const prefix = this.props.prefix;
        const icon = this.iconContainer.current;
        const offset = icon.offsetLeft;

        let key = data.key;
        if (data.key) {
            key = key.toLowerCase();
        }
        const scrollTable = document.querySelector('.newListViewContentInner');
        const scrollLeft = scrollTable.scrollLeft;
        sAction.openFilter(data.def, offset - scrollLeft, prefix, key, data.fieldRel);
    }

    render() {
        this.index++;
        const value = this.props.data;
        const orderBy = this.props.orderBy;
        const actFiltering = this.props.actFiltering;
        const filtering = this.props.filtering;
        const prefix = this.props.prefix;

        const name = this.props.data.def.get('name');
        let defaultValue = null;
        if (this.props.filterValues && this.props.filterValues[name]) {
            defaultValue = this.props.filterValues[name];
        }
        const attr = {
            defaultValue: defaultValue,
        };
        if (this.props.removeFieldText) {
            attr.key = this.index;
        }

        let asc = this.props.asc;
        const sortable = this.props.sortable;
        const type = this.props.data.def.get('type');
        // __________________________________________ LABEL
        let label;
        const fieldModule = value.def.get('fieldModule');
        // pokud je nastaveny alternativni nazev, zobrazit misto klasickeho nazvu
        if (value.alterName) {
            label = value.alterName;
        } else if (sAction.dataGet('reportWindow/alterName').size > 0) {
            label = sAction.findAlterName(value);
        } else {
            label = sAction.translate(value.label, fieldModule);
        }
        if (asc === 1) {
            asc = 0;
        } else {
            asc = 1;
        }
        if (!orderBy) {
            asc = 1;
        }
        // __________________________________________ LABEL END

        // ___________________________________________ ICONA (serazeni/filtrace)
        let icon = null;
        if (!actFiltering && sortable && prefix !== 'reportWindow/results') {
            icon = (
                <div
                    onClick={() => sAction.orderBy(value.key.toLowerCase(), asc, prefix)}
                    className={'orderArrowsContainer listViewHeadericonContainer '}
                >
                    <TooltipWrapper label={'LBL_SORT_ASCENDING'}>
                        <div
                            className={orderBy && asc === 0 ?
                                'iconfas-caretUp select' :
                                'iconfas-caretUp'
                            }
                        />
                    </TooltipWrapper>
                    <TooltipWrapper label={'LBL_SORT_DESCENDING'}>
                        <div
                            className={orderBy && asc === 1 ?
                                'iconfas-caretDown select' :
                                'iconfas-caretDown'
                            }
                        />
                    </TooltipWrapper>
                </div>
            );
        } else if (actFiltering) {
            let usedMark = null;
            if (filtering.state) {
                usedMark = <div className="filteringUsedMark" />;
            }

            icon = (
                <div ref={this.iconContainer} className="listViewHeadericonContainer">
                    <div
                        onClick={() => this.openFilter()}
                        className={'icon-filter listViewheaderFiltration'}
                    >
                        {usedMark}
                    </div>
                    <div
                        onClick={() => sAction.orderBy(value.key.toLowerCase(), asc, prefix)}
                        className={'orderArrowsContainer'}
                    >
                        <div
                            className={classnames(['iconfas-caretUp', {select: orderBy && +asc === 0}])}
                        />
                        <div
                            className={classnames(['iconfas-caretDown', {select: orderBy && +asc === 1}])}
                        />
                    </div>
                </div>
            );
        }

        let field;
        let containerClass = 'newListViewHeaderCell setWidth';
        let filterEnabled = true;
        if (actFiltering) {
            containerClass += ' activeFiltering';

            switch (type) {
                case 'ic':
                case 'phone':
                case 'currency':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <TextField
                                label={label}
                                {...attr}
                                type={'number'}
                                onChange={(e) => this.headerInputSearch(e)}
                            />
                        </div>
                    );
                    break;
                case 'enum':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <EnumField
                                label={label}
                                {...attr}
                                options={this.props.data.def.get('options')}
                                colors={this.props.data.def.get('colors')}
                                onChange={(e) => this.headerInputSearch(e)}
                            />
                        </div>
                    );
                    break;
                case 'multienum':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <EnumField
                                label={label}
                                options={this.props.data.def.get('options')}
                                onChange={(e) => this.headerInputSearch(e)}
                            />
                        </div>
                    );

                    if (this.props.data.def.get('name') === 'acm_contacts_text') {
                        field = (
                            <div className={'listviewHeaderInput ' + type}>
                                <RelateField
                                    label={label}
                                    {...attr}
                                    module={'Contacts'} // relate to
                                    fieldModule={this.props.data.def.get('fieldModule')} // relate from
                                    field={this.props.data.def.get('id_name')} // relate from
                                    onChange={(e) => this.headerInputSearch(e)}
                                    prefix={this.props.prefix}
                                />
                            </div>
                        );
                    }

                    break;
                case 'AcmDynamicEnum':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <EnumField
                                label={label}
                                options={this.props.data.def.get('options')}
                                dynEnum={true}
                                onChange={(e) => this.headerInputSearch(e)}
                            />
                        </div>
                    );
                    break;
                case 'date':
                case 'datetime':
                case 'datetimecombo':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <DateField
                                {...attr}
                                label={label}
                                onChange={(e) => this.headerInputSearch(e)}
                                from = {'listViewHeaderField'}
                            />
                        </div>
                    );
                    break;
                case 'relate':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <RelateField
                                label={label}
                                {...attr}
                                module={this.props.data.def.get('module')}
                                fieldModule={this.props.data.def.get('fieldModule')}
                                fieldRel={this.props.data.fieldRel}
                                field={this.props.data.def.get('name')}
                                def={this.props.data.def}
                                onChange={(e) => this.headerInputSearch(e)}
                                prefix={this.props.prefix}
                            />
                        </div>
                    );
                    break;
                case 'parent':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <ParentField
                                label={label}
                                module={fieldModule}
                                {...attr}
                                type={'text'}
                                onChange={(e, customParam) => this.headerInputSearch(e, customParam)}
                            />
                        </div>
                    );
                    break;
                case 'varchar':
                case 'name':
                case 'account_name':
                case 'url':
                case 'file':
                case 'id':
                case 'Ss':
                case 'text':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <TextField
                                label={label}
                                type={'text'}
                                {...attr}
                                onChange={(e) => this.headerInputSearch(e)}
                            />
                        </div>
                    );
                    break;

                case 'bool':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <BoolField
                                label={label}
                                {...attr}
                                onChange={(e) => this.headerInputSearch(e)}
                            />
                        </div>
                    );
                    break;
                case 'int':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <TextField
                                label={label}
                                type={'int'}
                                onChange={(e) => this.headerInputSearch(e)}
                            />
                        </div>
                    );
                    break;
                case 'decimal':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <TextField
                                label={label}
                                type={'decimal'}
                                onChange={(e) => this.headerInputSearch(e.replace(',', '.'))}
                            />
                        </div>
                    );
                    break;
                case 'multirelate':
                    field = (
                        <div className={'listviewHeaderInput ' + type}>
                            <MultirelateField
                                label={label}
                                type={'multirelate'}
                                name={name}
                                targetModule={this.props.data.def?.get('targetModule')}
                                {...attr}
                                onChange={(e) => this.headerInputSearch(e)}
                            />
                        </div>
                    );
                    break;
                default:
                    filterEnabled = false;
                    field = (
                        <div
                            title={label}
                            className={'newListViewHeaderCellLabel'}
                        >
              &nbsp;{label}
                        </div>
                    );
                    // eslint-disable-next-line no-console
                    console.log('Nenalezen filtr pro typ: ' + type);
                    break;
            }
        } else {
            let onClick = undefined;
            if (sortable && prefix !== 'reportWindow/results') {
                onClick = () => sAction.orderBy(value.key.toLowerCase(), asc, prefix);
            }
            field = (
                <div
                    title={label}
                    className={'newListViewHeaderCellLabel' + (onClick ? ' pointerClass' : '')}
                    onClick={onClick}
                >
                    {label}
                </div>
            );
        }
        // ___________________________________________ ICONA (serazeni/filtrace) END
        return (
            <div
                data-width={this.props.data.width}
                className={containerClass}
                data-last={this.props.last}
            >
                {field}
                <div className="newListViewHeaderCellIconSet">
                    {filterEnabled && icon}
                    {sAction.isServis() && <div className='listviewHeaderDividerServis' />}
                </div>
                <div
                    className={"changeWidthHolder" + (this.state.resizing ? ' resizingActive' : '')}
                    onMouseDown={(e) => {
                        sAction.rowResize(e, prefix);
                        this.setState({resizing: true});
                    }}
                    onTouchStart={(e) => {
                        sAction.rowResize(e, prefix);
                        this.setState({resizing: true});
                    }}
                    onMouseUp={() => {
                        this.setState({resizing: false});
                    }}
                    onTouchEnd={() => {
                        this.setState({resizing: false});
                    }}
                >
                    <div className="changeWidthHolder__wrapper">
                        <div className="changeWidthHolder__divider"></div>
                    </div>
                </div>
            </div>
        );
    }
}

NewListViewHeaderField.propTypes = {
    data: ImmutablePropTypes.recordOf({
        key: propTypes.string,
        label: propTypes.string,
        def: ImmutablePropTypes.map,
        records: ImmutablePropTypes.list,
        width: propTypes.string,
        sortable: propTypes.bool,
        disabled: propTypes.bool,
        alterName: propTypes.string,
        fieldRel: propTypes.string,
    }),
    prefix: propTypes.string,
    orderBy: propTypes.bool,
    actFiltering: propTypes.bool,
    filtering: propTypes.object,
    filterValues: propTypes.object,
    last: propTypes.bool,
    sortable: propTypes.bool,
    asc: propTypes.number,
    removeFieldText: propTypes.bool,
    filter: propTypes.list,
};
