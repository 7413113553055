import {useState, useEffect} from 'react';
import sAction from 'sAction';

/**
 * Wrapper for functions handling EditView
 * @return {{
 * setField: (function(string, object): void),
 * getFields: (function(): {}),
 * getGroupFields: (function(string): []),
 * getField: (function(string): {}),
 * setFields: (function(object): void),
 * }}
 */
export default function useFields() {
    /** @var {function} setFields */
    const [fields, setFields] = useState({});
    const [groups, setGroups] = useState({});

    /**
     * @return {{}}
     */
    const getFields = () => {
        return fields;
    };

    /**
     * Get one field defs by name
     * @param {string} field
     * @return {{}}
     */
    const getField = (field) => {
        return fields?.[field] || {};
    };

    /**
     * Get fields by group
     * @param {string} group
     * @return {[]}
     */
    const getGroupFields = (group) => {
        return groups?.[group]?.map((field) => fields[field]) || [];
    };

    /**
     * @param {string} field
     * @param {object} data
     * @param {string} module
     */
    const setField = (field, data, module) => {
        const tempFields = fields;
        tempFields[field] = {
            def: data,
            name: field,
            actEdit: false,
            customClass: null,
            customStyle: null,
            value: '',
        };
        if(module !== 'relationField'){
            sAction.moduleTranslate[module][data.vname] = data.translation;
        } else {
            for (const relationModule of data?.message?.relationField?.modules ?? []){
                sAction.moduleTranslate[relationModule][data.vname] = data.translation;
            }
        }
        setFields({...tempFields});
    };

    /**
     *
     * @param {object} field
     */
    const removeFieldFromLeftPanel = (field) => {
        const tempFields = fields;
        delete tempFields[field];
        setFields({...tempFields});
    };

    useEffect(() => {
        const tempGroups = {};
        Object.keys(fields).forEach((field) => {
            const group = fields[field].def.group;
            if (!group) {
                return;
            }
            if (!tempGroups[group]) {
                tempGroups[group] = [];
            }
            tempGroups[group].push(field);
        });
        for (const group in tempGroups) {
            if (['shipping_address', 'billing_address', 'primary_address', 'secondary_address', 'alt_address'].includes(group)) {
                tempGroups[group] = [
                    `${group}_street`,
                    `${group}_city`,
                    `${group}_postalcode`,
                    `${group}_state`,
                    `${group}_kraj`,
                    `${group}_country`,
                ];
            }
        }
        setGroups(tempGroups);
    }, [fields]);

    return {
        getFields,
        getField,
        getGroupFields,
        setFields,
        setField,
        removeFieldFromLeftPanel,
    };
}
