/**
 *
 * @param {Object} editor
 * @returns {String} Html with inline styles
 */
export default function exportHtml(editor) {
    const html = editor.getHtml();
    const css = editor.getCss();
    const exportHtml =`${html} <style>${css}</style>`;

    return exportHtml;
}
