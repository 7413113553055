import sAction from 'sAction';
import lines from './lines';
import qrCode from './qrCode';

/**
 * @param {Object} editor
 * @param {String} module
 * @returns {Array}
 */
export default function blocks(editor, module) {
    /**
     *
     */
    const getLogo = () => {
        sAction.rest.get('getLogo', (res) => {
            if (res.status) {
                editor.Blocks.add('header', {
                    label: 'Header',
                    media: '<span class="iconfas-heading printKitsBlockIcon"></span>',
                    activate: true,
                    content: `
                            <htmlpageheader name="Header" id="pageHeader">
                                <table style="width: 100%;">
                                    <tr>
                                        <td style="width: 20%;"><img src="data:image/png;base64, ${res.logo}"></td>
                                        <td style="width: 60%;"></td>
                                        <td style="width: 20%;"><span>{#printCompany#}</span></td>
                                    </tr>
                                </table>
                            </htmlpageheader>`,
                });
                editor.Blocks.add('logo', {
                    label: 'Logo',
                    media: '<span class="iconfas-heading printKitsBlockIcon"></span>',
                    activate: true,
                    content: `<div><img src="data:image/png;base64, ${res.logo}"></div>`,
                });
            }
        }, false);
    };

    getLogo();

    editor.Blocks.add('footer', {
        label: 'Footer',
        media: '<span class="iconfas-footer printKitsBlockIcon"></span>',
        draggable: true,
        content: `<htmlpagefooter name="Footer" id="pageFooter">
                    <table style="width: 100%;">
                        <tr>
                            <td style="width: 33%;"><div>Vystavil:</div></td>
                            <td style="width: 33%;"><div>{#first_name#} {#last_name#}</div></td>
                            <td style="width: 33%;"></td>
                        </tr>
                        <tr>
                            <td style="width: 33%;"><div>E-mail:</div></td>
                            <td style="width: 33%;"><div>{#email1#}</div></td>
                            <td style="width: 33%;"></td>
                        </tr>
                        <tr>
                        <td style="width: 33%;"><div>Telefon:</div></td>
                        <td style="width: 33%;"><div>{#phone_mobile#}</div></td>
                        <td style="width: 33%;"><div>{PAGENO}</div></td>
                        </tr>
                    </table>
                </htmlpagefooter>`,
        activate: true,
    });
    // Blocks
    lines(editor, module);
    if (module === 'acm_invoices' || module === 'acm_orders') {
        qrCode(editor, module);
    }

    // Disable blocks
    editor.Blocks.remove('link-block');
    editor.Blocks.remove('link');
    editor.Blocks.remove('quote');
    editor.Blocks.remove('video');
    editor.Blocks.remove('map');
}
