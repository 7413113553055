import React from 'react';
import PureComponent from '../pure';
import PropTypes from 'prop-types';

import MoreOptions from '../formElements/MoreOptions';
import TickBox from '../formElements/TickBox';
import sAction from 'sAction';
import ListViewPagination from './ListViewPagination';
import TooltipWrapper from 'ROOT/src/components/Tooltip/TooltipWrapper';

export default class ListViewFooter extends PureComponent {
    /**
   *
   * @param {string} field
   * @param {string} type
   * @param {string} value
   */
    addFilter(field, type, value) {
    //    this.props.parent.props.parent.filterClose();
        const prefix = this.props.prefix;
        const module = this.props.module;
        if (['Contacts', 'Leads', 'Prospects', 'Users'].includes(module)) {
            field = 'last_name';
        } else if (module === 'Documents') {
            field = 'document_name';
        }
        const filter = {
            field: field,
            type: type,
            value: value,
            footer: true,
        };
        const filters = new Array(filter);

        const data = {
            field: field,
            filters: filters,
            operator: 'and',
            prefix: prefix,
            reload: true,
        };
        if (value !== '%%') {
            sAction.addFilter(data);
        } else {
            sAction.removeFilter(data);
        }
    }
    /**
     *
     */
    selectPage() {
        const prefix = this.props.prefix;
        sAction.toggleAll(true, prefix);
    }
    /**
     *
     */
    selectAll() {
        const prefix = this.props.prefix;
        sAction.dsClear();
        sAction.dsAdd('set', prefix + '/selected', 'all');
        sAction.dsAdd('set', prefix + '/selectPage', true);
        sAction.dsProcess();
    }
    /**
     *
     */
    removeSelect() {
        const prefix = this.props.prefix;
        sAction.dsClear();
        sAction.dsAdd('set', prefix + '/selected', []);
        sAction.dsAdd('set', prefix + '/selectPage', false);
        sAction.dsProcess();
    }
    /**
     *
     */
    resetWidth() {
        const view = sAction.getViewName();
        const way = view + this.props.module;
        const filterData = sAction.getStorage('listFilterData');
        if (filterData[way]['widthInfo']) {
            filterData[way]['widthInfo'] = [];
            sAction.setStorage('listFilterData', filterData);
            sAction.afterLoadListView(this.props.prefix);
        }
    }

    render() {
        const offset = this.props.offset;
        const rowCount = this.props.rowCount;
        let selected = this.props.selected;
        const selectedActive = this.props.selectedActive;
        const page = this.props.page;
        const filter = this.props.filter;
        let activeLetter = null;

        if (selected === 'all') {
            selected = sAction.translate('LBL_ALL');
        }

        const countInfo = (
            <React.Fragment>
                {'|'}
                <TooltipWrapper label={'LBL_SELECTED_RECORDS'}>
                    <TickBox checked={true}/>
                </TooltipWrapper>
                {':' + selected}
            </React.Fragment>
        );

        filter.forEach((field) => {
            if (['name', 'last_name', 'document_name'].includes(field.field)) {
                const filters = field.filters;
                filters.forEach((filter) => {
                    if (filter.type === 'start') {
                        activeLetter = filter.value;
                    }
                });
            }
        });
        const filterField = 'name';

        const alphabet = [
            'A',
            'B',
            'C',
            'D',
            'E',
            'F',
            'G',
            'H',
            'CH',
            'I',
            'J',
            'K',
            'L',
            'M',
            'N',
            'O',
            'P',
            'Q',
            'R',
            'S',
            'T',
            'U',
            'V',
            'W',
            'X',
            'Y',
            'Z',
        ];
        const alphabetRender = [];
        alphabet.forEach((letter, key) => {
            alphabetRender.push(
                <div
                    onClick={() => this.addFilter(filterField, 'start', letter)}
                    key={key}
                    className={activeLetter === letter ? 'letter activeLetter' : 'letter'}
                >
                    <div>{letter}</div>
                </div>,
            );
        });

        const type = this.props.type;
        let pomClass = '';
        if (type === 'rightPanelQuotes') {
            pomClass = ' rightPanelQuotes';
        }

        return (
            <div className="listViewFooter">
                {type !== 'reportWindow' && selectedActive && (
                    <div className={'countInfoContainer footerBlock' + pomClass}>
                        <div className="countInfo">
                            <MoreOptions
                                options={[
                                    {
                                        label: sAction.translate('LBL_SELECT_PAGE'),
                                        icon: 'icon-selectPage',
                                        value: 'selectPage',
                                    },
                                    {
                                        label: sAction.translate('LBL_SELECT_ALL_RECORDS'),
                                        icon: 'icon-selectAll',
                                        value: 'selectAll',
                                    },
                                    {
                                        label: sAction.translate('LBL_UNDO_ALL_SELECT'),
                                        icon: 'icon-selectUndo',
                                        value: 'removeSelect',
                                    },
                                    {
                                        label: sAction.translate('LBL_RESET_COLUMN_WIDTH'),
                                        icon: 'icon-resetWidths',
                                        value: 'resetWidth',
                                    },
                                ]}
                                onClick={(e, val) => this[val](e)}
                            >
                                <TooltipWrapper label={'LBL_MORE_OPTIONS'}>
                                    <div className="icon-More listViewSelectMoreOptions" />
                                </TooltipWrapper>
                            </MoreOptions>
                            {countInfo}
                        </div>
                    </div>
                )}

                <ListViewPagination offset={offset}
                    limit={this.props.limit}
                    rowCount={rowCount}
                    rowTotalCount={this.props.rowTotalCount}
                    page={page}
                    prefix={this.props.prefix}
                    namespace={this.props.namespace}
                    query={this.props.query}
                    module={this.props.module}
                />
            </div>
        );
    }
}

ListViewFooter.propTypes = {
    limit: PropTypes.number,
    rowTotalCount: PropTypes.number,
    prefix: PropTypes.string,
    namespace: PropTypes.string,
    module: PropTypes.string,
    offset: PropTypes.number,
    rowCount: PropTypes.number,
    selected: PropTypes.number,
    selectedActive: PropTypes.bool,
    page: PropTypes.number,
    filter: PropTypes.object,
    type: PropTypes.string,
    query: PropTypes.string,
};
