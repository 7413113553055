/**
 *
 * @param e
 * @param prefix
 */
export default function rowResize(e, prefix) {
    if (this.dataGet(prefix+'/openFilterData') != null) {
        this.dataSet(prefix+'/openFilterData/open', false);
    }
    const th = e.target.closest('.newListViewHeaderCell');
    const table = e.target.closest('.newListView');
    const index = Array.from(th.parentNode.childNodes).indexOf(th);
    const linesRow = table.querySelector('.newListViewLines').children[index];

    const width = th.clientWidth;

    this.index = index;
    this.resizeStart = e.clientX || e.targetTouches[0].pageX;
    this.resizeWidthStart = width;
    this.resizeRows = [th, linesRow];
    this.activeResizeRow = true;
}
