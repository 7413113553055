/**
 *
 * @param {Object} editor
 * @param {String} module
 */
export default function qrCode(editor, module) {
    editor.Blocks.add('qrCode', {
        label: 'QR code',
        media: '<span class="iconfas-lines printKitsBockIcon"></span>',
        content: `
        <summarytable id="summarytable">
        <table style="font-size: 12px; width: 100%;">
        <tbody>
          <tr>
            <td style="padding-left: 15px; width: 35%;">
              <span><b> QR platba:
                </b></span>
              <div>
                <qrcode><img src="" id="qrCodePayment" style="height: 150px; width: 150px;" /></qrcode>
              </div>
            </td>
            <td style="width: 65%;">
              <table style="width: 100%;">
                <tbody>
                  <tr id='vatSum'>
                    <td style="width: 180px;"></td>
                    <td style="width: 120px; text-align: right;">{*withoutVATsum*}</td>
                    <td style="width: 50px; text-align: right;">{*VATValue*}</td>
                    <td style="width: 120px; text-align: right;">{*VATSum*}</td>
                    <td style="text-align: right; width: 120px;">{*withVATSum*}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <br/>
                      <br/>Úřední razítko:
                      <br/>Podpis:
                    </td>
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td colspan="5">
                      <br/>
                      <br/>
                      <br/>
                      <brt>
                      </brt>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      </summarytable>
        `,
    });
}
